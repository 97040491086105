import cx from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { Image } from "../../../../../../components/images/Image";
import { T } from "../../../../../../components/translation/T";
import { TerminalType } from "../../../../../../data/models/ContractTypes";
import styles from "../Offering.module.scss";

interface Props {
  terminalType: TerminalType;
  quantity: number;
  price?: number;
  addOn?: ReactNode;
}

// We should probably present the actual model here
const terminalTypeMapping = {
  [TerminalType.WIRED_INTEGRATED]: "Wired Integrated",
  [TerminalType.WIRELESS_INTEGRATED]: "Wireless",
  [TerminalType.WIRELESS_STANDALONE]: "Wireless",
  [TerminalType.WIRELESS_STANDALONE_ANDROID]: "Wireless Android",
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]: "Wireless Android",
  [TerminalType.WIRED_INTEGRATED_ANDROID]: "Wired Integrated Android",
};

function isAndroid(terminalType: TerminalType) {
  switch (terminalType) {
    case TerminalType.WIRED_INTEGRATED_ANDROID:
    case TerminalType.WIRELESS_INTEGRATED_ANDROID:
    case TerminalType.WIRELESS_STANDALONE_ANDROID:
      return true;

    default:
      return false;
  }
}

export const TerminalCard: FunctionComponent<Props> = ({
  terminalType,
  quantity,
  addOn = null,
}) => {
  let terminalImage: ImageData = IMAGE_MAPPING[terminalType];

  return (
    <div
      className={cx(styles.terminalCard, {
        [styles.android]: isAndroid(terminalType),
      })}
    >
      <div className={styles.iconContainer}>
        <div className={styles.image}>
          <Image
            src={terminalImage.src}
            ratio={terminalImage.ratio}
            alt={terminalImage.alt}
          />
        </div>
      </div>
      <div className={styles.terminalInfo}>
        <span className={styles.textValue}>
          <T>{terminalTypeMapping[terminalType]}</T>
        </span>
        <div className={styles.terminalInfoRow}>
          <div className={styles.terminalData}>
            <span className={styles.secondaryText}>
              <T>Quantity</T>:
            </span>
            <span className={styles.quantity}>
              {quantity} <span className="text-mini">{addOn}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ImageData {
  src: string;
  ratio: number;
  alt: string;
}

const IMAGE_MAPPING: Record<TerminalType, ImageData> = {
  [TerminalType.WIRED_INTEGRATED]: {
    src: "/images/terminals/desk_5000_197x432.png",
    ratio: 197 / 432,
    alt: "Lane terminal",
  },
  [TerminalType.WIRELESS_INTEGRATED]: {
    src: "/images/terminals/move_5000_210x435.png",
    ratio: 210 / 435,
    alt: "Move terminal",
  },
  [TerminalType.WIRELESS_STANDALONE]: {
    src: "/images/terminals/move_5000_210x435.png",
    ratio: 210 / 435,
    alt: "Move terminal",
  },
  [TerminalType.WIRED_INTEGRATED_ANDROID]: {
    src: "/images/terminals/rx5000.webp",
    ratio: 1,
    alt: "Ingenico AXIUM RX5000",
  },
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]: {
    src: "/images/terminals/dx8000.webp",
    ratio: 1,
    alt: "Ingenico AXIUM DX8000",
  },
  [TerminalType.WIRELESS_STANDALONE_ANDROID]: {
    src: "/images/terminals/dx8000.webp",
    ratio: 1,
    alt: "Ingenico AXIUM DX8000",
  },
};
