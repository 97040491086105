import React from "react";
import cx from "classnames";
import {
  AccountType,
  KlarnaAccount as KlarnaAccountInterface,
} from "../../../../data/dataBank";
import styles from "./KlarnaAccount.module.scss";
import { T } from "../../../../components/translation/T";
import { useTranslation } from "react-i18next";

interface Props {
  account: KlarnaAccountInterface;
  selected: boolean;
  setSelectedAccount: (account: KlarnaAccountInterface) => void;
  disabled: boolean;
  isLoading: boolean;
}

function getText(account: KlarnaAccountInterface) {
  if (account.accountType !== AccountType.DEFAULT) {
    return (
      <>
        <T>
          The account type was not supplied or was incorrect, e.g it was a
          savings account.
        </T>
        <br />
        <T>This account is therefore not selectable.</T>
      </>
    );
  }

  if (account.accountStatus === "INVALID_TYPE") {
    return (
      <>
        <T>The account type was not supplied or was incorrect.</T>
        <br />
        <T>This account is therefore not selectable.</T>
      </>
    );
  }

  if (account.accountStatus === "INVALID_CURRENCY") {
    return (
      <>
        <T>
          The account can't be used since it does not have the valid currency.
        </T>
        <br />
        <T>This account is therefore not selectable.</T>
      </>
    );
  }

  if (account.accountStatus === "NO_MATCH") {
    return (
      <>
        <T>
          The holder of the account does not match either the company or the
          selected account holder.
        </T>
        <br />
        <T>This account is therefore not selectable.</T>
      </>
    );
  }

  return (
    <>
      <T>Your bank did not provide adequate information for this account.</T>
      <br />
      <T>This account is therefore not selectable.</T>
    </>
  );
}

export const KlarnaAccount: React.FunctionComponent<Props> = ({
  selected,
  account,
  setSelectedAccount,
  disabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  const isSelectable =
    account.accountType === AccountType.DEFAULT &&
    (account.accountStatus === "MATCH" ||
      (account.accountStatus === "NO_HOLDER" && !!account.iban));

  return (
    <li
      className={cx(styles.klarnaAccount, {
        [styles.selected]: selected,
        [styles.disabled]: !isSelectable || disabled,
      })}
    >
      <button
        className={styles.button}
        onClick={() => {
          if (isLoading) {
            return;
          }
          setSelectedAccount(account);
        }}
        disabled={!isSelectable || disabled}
      >
        <div>
          <div
            className={cx(styles.tickbox, {
              [styles.selected]: selected,
            })}
          />
        </div>
        <div className={cx(styles.accountInfo, "text-small")}>
          <ul>
            <li>
              <b>{account.accountName || `${account.iban} IBAN` || "-"}</b>
            </li>
            {isSelectable ? (
              <li>
                <div>
                  {account.accountHolder || (
                    <i>
                      {t(
                        "No account holder name was received for this account"
                      )}
                    </i>
                  )}
                </div>
              </li>
            ) : (
              <li className="klarna-account-info">{getText(account)}</li>
            )}
          </ul>
        </div>
      </button>
    </li>
  );
};
