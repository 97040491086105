import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./StateDefault.module.scss";
import uploadStyles from "./Klarna.module.scss";
import { View } from "../Bank";
import { T } from "../../../../components/translation/T";
import { Upload } from "./Upload";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Country } from "../../../../data/models/ContractTypes";

interface Props {
  onRetry: () => void;
  onViewChange: (view: View) => void;
  country: Country;
  disabled?: boolean;
  buttonName?: ReactNode;
}

export const StateDefault: React.FunctionComponent<Props> = ({
  onRetry,
  onViewChange,
  country,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <Trans t={t}>
            When you click the button, you will be asked to login to your bank
            to select your <u>corporate account</u>.
          </Trans>
        </div>

        <Button onClick={onRetry} disabled={disabled}>
          <T>Add payout account</T>
        </Button>
      </div>

      {country === Country.SWEDEN ? (
        <div className={cx("text-small", "text-passive", uploadStyles.upload)}>
          <T>Or, you can add one by</T>{" "}
          <Button
            text
            className="text-small"
            onClick={() => onViewChange(View.BANKGIRO)}
          >
            <T>entering your bank account</T>
          </Button>
          .
        </div>
      ) : (
        <Upload onViewChange={onViewChange} disabled={disabled}>
          <T>Or, you can add a bank account by</T>
        </Upload>
      )}
    </>
  );
};
