import { QueryOptions } from "@tanstack/react-query";
import { API } from "../network/API";
import { Associate, AssociateRole } from "./dataMerchant";
import { AssociateId, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

export function isPrimary(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
  );
}

export function isOwner(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1
  );
}

export function isSignee(associate?: Associate) {
  return !!associate && associate.roles.indexOf(AssociateRole.SIGNATORY) > -1;
}

export function isSelectedSignee(associate?: Associate) {
  return (
    !!associate &&
    associate.roles.indexOf(AssociateRole.SELECTED_SIGNATORY) > -1
  );
}

export function isAccountHolder(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
  );
}

export function hasRole(role: AssociateRole, associate?: Associate) {
  return !!associate && associate.roles.includes(role);
}

export type Combination = {
  associateIds: AssociateId[];
};

enum WhitelistedEndpoint {
  FETCH_ASSOCIATES = "fetchAssociates",
  FETCH_SIGNATORY_COMBINATIONS = "fetchSignatoryCombinations",
  GET_ASSOCIATES = "getAssociates",
  GET_ASSOCIATES_KEY = "getAssociatesKey",
  VALIDATE_EMAIL = "validateEmail",
}

const Associates = {
  [WhitelistedEndpoint.GET_ASSOCIATES]: (linkId: LinkId) =>
    API.get<Associate[]>(`/api/merchant/${linkId}/associates`),

  [WhitelistedEndpoint.FETCH_ASSOCIATES]: (
    linkId: LinkId
  ): QueryOptions<Associate[]> => ({
    queryKey: Associates.getAssociatesKey(linkId),
    queryFn: () => API.get<Associate[]>(`/api/merchant/${linkId}/associates`),
  }),

  saveAssociate: (linkId: LinkId, associate: Associate, isNew?: boolean) => {
    if (isNew) {
      return API.post<Associate>(
        `/api/merchant/${linkId}/associates`,
        associate
      );
    } else {
      return API.post<Associate>(
        `/api/merchant/${linkId}/associates/${associate.associateId}`,
        associate
      );
    }
  },

  removeAssociate: (linkId: LinkId, associate: Associate) =>
    API.delete<void>(
      `/api/merchant/${linkId}/associates/${associate.associateId}`
    ),

  [WhitelistedEndpoint.GET_ASSOCIATES_KEY]: (linkId: LinkId) => {
    return ["associates", linkId];
  },

  [WhitelistedEndpoint.FETCH_SIGNATORY_COMBINATIONS]: (
    linkId: LinkId
  ): QueryOptions<Combination[]> => ({
    queryKey: ["signatories", linkId],
    queryFn: () =>
      API.get<Combination[]>(`/api/merchant/${linkId}/signing-combinations`),
  }),

  [WhitelistedEndpoint.VALIDATE_EMAIL]: (email: string, linkId: LinkId) =>
    API.post<{ valid: boolean }>(`/api/merchant/${linkId}/email`, {
      email,
    }),
};

let associates: typeof Associates;

export function dataAssociates(access: Access) {
  if (!associates) {
    associates = getProxy(
      Associates,
      access,
      Object.values(WhitelistedEndpoint)
    );
  }

  return associates;
}
