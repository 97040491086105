import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { Form, FormContainer } from "../../../components/form/Form";
import { NumberInput } from "../../../components/form/NumberInput";
import { TextArea } from "../../../components/form/TextArea";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Products } from "../../../components/products/Products";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { getIntlNumberFormat } from "../../../components/utils";
import { FinancialData, dataFinancial } from "../../../data/dataFinancial";
import { Associate, Contract, dataMerchant } from "../../../data/dataMerchant";
import { useCountry } from "../../../hooks/useCountry";
import { useLinkId } from "../../../hooks/useLinkId";
import { CurrencyByCountry } from "../../../i18n";
import { contractState } from "../../../state/contractState";
import { EstimatedRangeFields } from "./EstimatedRangeFields";
import { default as lodashEqual } from "lodash.isequal";
import { Address, ProductType } from "../../../data/models/ContractTypes";
import { ButtonPaneWithLabel } from "../../../components/interactions/Buttons/ButtonPaneInput";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";
import { Section } from "../../../components/section/Section";
import { TextInput } from "../../../components/form/TextInput";
import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { dataAssociates } from "../../../data/dataAssociates";
import { useSuspendedQueries } from "../../../hooks/useSuspendedQueries";
import { InvoiceToggle } from "./InvoiceAddress/InvoiceToggle";
import { routeState } from "../../../state/routeState";
import { Access } from "../../../data/proxy";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { InfoBox } from "../../../components/boxes/InfoBox";
import styles from "./Financials.module.scss";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";

export enum InvoiceEmailStatus {
  REQUEST_CODE = "request-code",
  CONFIRM_CODE = "confirm-code",
  CONFIRMED_AS_PRIMARY = "confirmed-as-primary",
  CONFIRMED_WITH_CODE = "confirmed-with-code",
  PENDING = "pending",
  ERROR = "error",
}

export enum InvoiceType {
  EMAIL = "email",
  ADDRESS = "address",
}

interface FinancialSave extends Omit<FinancialData, "thirdPartyPayments"> {
  thirdPartyPayments?: boolean;
}

function getCostFn(language: string) {
  return (val: number) => `${getIntlNumberFormat(language, val)}`;
}

function shouldSaveInvoice(productType: ProductType) {
  return (
    productType === ProductType.BAMBORA_DEVICE ||
    productType === ProductType.BAMBORA_DEVICE_SHORT_TERM ||
    productType === ProductType.BAMBORA_ONE ||
    productType === ProductType.BAMBORA_ONE_SHORT_TERM
  );
}

function getInitialInvoiceType(contract: Contract): InvoiceType | undefined {
  if (contract.contractData.invoiceEmail) {
    return InvoiceType.EMAIL;
  }

  if (!!contract.contractData.invoiceAddress?.street) {
    return InvoiceType.ADDRESS;
  }
}

function getInitialStatus(contract: Contract, primary?: Associate) {
  const { invoiceEmail } = contract.contractData;
  if (!invoiceEmail) {
    // Yes, always default to primary email
    return InvoiceEmailStatus.CONFIRMED_AS_PRIMARY;
  }

  if (!primary) {
    // Something is fishy, we should always have a primary
    return InvoiceEmailStatus.REQUEST_CODE;
  }

  if (invoiceEmail === primary.contact.email) {
    return InvoiceEmailStatus.CONFIRMED_AS_PRIMARY;
  }

  return InvoiceEmailStatus.CONFIRMED_WITH_CODE;
}

const defaultAddress = {
  street: "",
  postalCode: "",
  city: "",
  countryCode: undefined,
};

const MAX_LENGTH_DESCRIPTION = 255;

export const Financials: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const country = useCountry();
  const formContainer = useRef<FormContainer>();
  const { access } = useRecoilValue(routeState);
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const [contract, setContract] = useRecoilState(contractState);
  const [code, setCode] = useState<string>("");
  const [codeWarning, setCodeWarning] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const latestVerifiedInvoiceEmail = useRef<string>(
    contract.contractData.invoiceEmail || ""
  );

  const [{ data: response }, { data: associates }] = useSuspendedQueries<any>({
    queries: [
      dataFinancial(access).fetchFinancial(linkId),
      dataAssociates(access).fetchAssociates(linkId),
    ],
  });

  const primary = useMemo(() => {
    return (associates as Associate[]).find(
      (associate) =>
        associate.associateId === contract.contractViewer.associateId
    );
  }, [associates, contract]);

  const [invoiceEmail, setInvoiceEmail] = useState<string | undefined>(
    contract.contractData.invoiceEmail || primary?.contact.email
  );

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceEmailStatus>(
    getInitialStatus(contract, primary)
  );

  const [data, setData] = useState<FinancialSave>(response);

  const getCost = useMemo(() => getCostFn(language), [language]);

  const [hasSameInvoiceAddress, setHasSameInvoiceAddress] = useState<boolean>(
    lodashEqual(
      contract.contractData.address,
      contract.contractData.invoiceAddress
    )
  );
  const [isEmailInvoice, setIsEmailInvoice] = useState<InvoiceType | undefined>(
    getInitialInvoiceType(contract)
  );

  const [invoiceAddress, setInvoiceAddress] = useState<Address>(
    hasSameInvoiceAddress
      ? defaultAddress
      : { ...defaultAddress, ...contract.contractData.invoiceAddress }
  );

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(dataFinancial(access).fetchFinancial(linkId));
  }, [linkId, access, queryClient]);

  const {
    mutate: onSave,
    isLoading,
    isError,
    reset,
  } = useMutation(
    () => {
      const values: FinancialSave = {
        ...data,
        thirdPartyPayments: false, // TODO this is just to pass backend validation. remove once solved
      };

      if (!shouldSaveInvoice(contract.productType)) {
        return dataFinancial(access).postFinancial(
          linkId,
          values as FinancialData
        );
      }

      const promises = [];

      if (isEmailInvoice === InvoiceType.EMAIL) {
        if (latestVerifiedInvoiceEmail.current !== invoiceEmail) {
          if (invoiceEmail === primary?.contact.email) {
            promises.push(
              dataFinancial(access).confirmPrimaryEmailAsInvoiceEmail(linkId)
            );
          } else {
            promises.push(
              dataFinancial(access).confirmInvoiceVerificationCode(linkId, code)
            );
          }
        }
      } else {
        if (hasSameInvoiceAddress) {
          promises.push(
            dataMerchant(access).saveInvoiceAddress(linkId, {
              ...contract.contractData.address,
            })
          );
        } else {
          promises.push(
            dataMerchant(access).saveInvoiceAddress(linkId, {
              ...invoiceAddress,
            })
          );
        }
      }

      promises.push(
        dataFinancial(access).postFinancial(linkId, values as FinancialData)
      );

      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        let invoice: Address;

        if (hasSameInvoiceAddress) {
          invoice = {
            ...contract.contractData.address,
          };
        } else {
          invoice = {
            ...invoiceAddress,
          };
        }

        setContract((prev) => {
          let contract = { ...prev };

          if (isEmailInvoice === InvoiceType.EMAIL) {
            contract = {
              ...prev,
              contractData: {
                ...prev.contractData,
                invoiceAddress: {},
                invoiceEmail,
              },
            };
          } else {
            contract = {
              ...prev,
              contractData: {
                ...prev.contractData,
                invoiceAddress: invoice,
                invoiceEmail: "",
              },
            };
          }

          return contract;
        });

        invalidateCache();
        next();
      },
    }
  );

  useEffect(() => {
    if (!isError) {
      return;
    }

    setTimeout(() => {
      reset();
    }, 6000);
  }, [isError, reset]);

  const minValidator = useMemo(() => {
    if (!data.estimatedTransactionsPerYear) {
      return;
    }

    if (!data.estimatedRangeOfTransactionValueMin) {
      return;
    }

    const val =
      data.estimatedTransactionsPerYear *
      data.estimatedRangeOfTransactionValueMin;

    const formattedVal = getCost(val);

    const text = (
      <T
        id={
          "Value must exceed {{formattedVal}} {{currency}} (min transaction value * number of transactions)"
        }
        options={{
          formattedVal,
          currency: CurrencyByCountry[country],
        }}
      />
    );
    return new MinValidator(val, text);
  }, [
    getCost,
    country,
    data.estimatedTransactionsPerYear,
    data.estimatedRangeOfTransactionValueMin,
  ]);

  const maxValidator = useMemo(() => {
    if (!data.estimatedTransactionsPerYear) {
      return;
    }

    if (!data.estimatedRangeOfTransactionValueMax) {
      return;
    }

    const val =
      data.estimatedTransactionsPerYear *
      data.estimatedRangeOfTransactionValueMax;

    const formattedVal = getCost(val);

    const text = (
      <T
        id="Value can not exceed {{formattedVal}} {{currency}} (max transaction value * number of transactions)"
        options={{
          formattedVal,
          currency: CurrencyByCountry[country],
        }}
      />
    );
    return new MaxValidator(val, text);
  }, [
    getCost,
    country,
    data.estimatedTransactionsPerYear,
    data.estimatedRangeOfTransactionValueMax,
  ]);

  const hint = useMemo(() => {
    const minHint =
      data.estimatedTransactionsPerYear &&
      data.estimatedRangeOfTransactionValueMin
        ? data.estimatedTransactionsPerYear *
          data.estimatedRangeOfTransactionValueMin
        : 0;

    const maxHint =
      data.estimatedTransactionsPerYear &&
      data.estimatedRangeOfTransactionValueMax
        ? data.estimatedTransactionsPerYear *
          data.estimatedRangeOfTransactionValueMax
        : 0;

    if (minHint && maxHint) {
      return (
        <T
          id="Between {{min}} {{currency}} and {{max}} {{currency}}"
          options={{
            currency: CurrencyByCountry[country],
            min: getCost(minHint),
            max: getCost(maxHint),
          }}
        />
      );
    }

    if (minHint) {
      return (
        <T
          id="From {{min}} {{currency}}"
          options={{
            currency: CurrencyByCountry[country],
            min: getCost(minHint),
          }}
        />
      );
    }

    if (maxHint) {
      return (
        <T
          id="Up to {{max}} {{currency}}"
          options={{
            currency: CurrencyByCountry[country],
            max: getCost(maxHint),
          }}
        />
      );
    }
  }, [
    getCost,
    country,
    data.estimatedTransactionsPerYear,
    data.estimatedRangeOfTransactionValueMin,
    data.estimatedRangeOfTransactionValueMax,
  ]);

  const validators = useMemo(() => {
    const v = [new RequiredValidator("Estimated annual turnover is required")];

    if (minValidator) {
      v.push(minValidator);
    }

    if (maxValidator) {
      v.push(maxValidator);
    }

    return v;
  }, [minValidator, maxValidator]);

  return (
    <>
      <Form
        className="flex-1"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (isEmailInvoice === InvoiceType.ADDRESS) {
            onSave();
            return;
          }

          if (
            latestVerifiedInvoiceEmail.current !== invoiceEmail &&
            primary?.contact.email !== invoiceEmail
          ) {
            setCodeWarning(true);
            return;
          }

          onSave();
        }}
        formContainer={formContainer}
      >
        <h2>
          <T>Financial information</T>
        </h2>

        <T>
          We would like to get to know your business in more detail and will
          therefore ask a few questions about your business.
        </T>

        <div className="m-top-40">
          <TextArea
            className={styles.desc}
            label="How would you describe your company's core business?"
            placeholder={t(
              'E.g. "Operates a lunch restaurant that sells home cooked food and drinks with à la carte in the evenings."'
            )}
            onChange={(value) => {
              setData((prev) => ({
                ...prev,
                businessModel: value,
              }));
            }}
            value={data.businessModel}
            validators={[
              new RequiredValidator("Value is required"),
              new MaxLengthValidator(
                MAX_LENGTH_DESCRIPTION,
                `Description must not exceed ${MAX_LENGTH_DESCRIPTION} characters`
              ),
            ]}
          />
        </div>

        <div className="m-top-10">
          <TextArea
            label="What are the most common goods/services your company sells?"
            placeholder={t('E.g. "We sell lunch with accompanying drink"')}
            onChange={(value) => {
              setData((prev) => ({
                ...prev,
                productDescription: value,
              }));
            }}
            value={data.productDescription}
            validators={[new RequiredValidator("Value is required")]}
          />
        </div>

        <hr />

        <div className="m-top-30">
          <EstimatedRangeFields
            onChange={(min, max) => {
              setData({
                ...data,
                estimatedRangeOfTransactionValueMin: min,
                estimatedRangeOfTransactionValueMax: max,
              });
            }}
            {...{ ...data }}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedTransactionsPerYear) =>
              setData({
                ...data,
                estimatedTransactionsPerYear,
              })
            }
            label="Estimated number of transactions per year regardless of payment method"
            value={data.estimatedTransactionsPerYear}
            placeholder={`${t("E.g.")} ${getIntlNumberFormat(language, 6000)}`}
            validators={[
              new RequiredValidator("Value is required"),
              new MinValidator(1, "Value must be positive"),
            ]}
            min={1}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedTransactionsByCardPerYear) =>
              setData({
                ...data,
                estimatedTransactionsByCardPerYear,
              })
            }
            label="Approximately how much of your turnover comes from card payments (%)?"
            value={data.estimatedTransactionsByCardPerYear}
            validators={[
              new RequiredValidator(
                "Estimated percentage of card transactions is required"
              ),
              new MinValidator(0, "Value can not be negative"),
              new MaxValidator(100, "Value can not be above 100%"),
            ]}
            suffix="%"
            min={0}
            max={100}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedAnnualTurnover) =>
              setData({
                ...data,
                estimatedAnnualTurnover,
              })
            }
            label={"What is the total turnover of your company per year?"}
            value={data.estimatedAnnualTurnover}
            validators={validators}
            suffix={" " + CurrencyByCountry[country]}
            placeholder={`${t("E.g.")} ${getIntlNumberFormat(
              language,
              100000
            )} ${CurrencyByCountry[country]}`}
            min={1}
            hint={hint}
            disableCache={true}
          />
        </div>

        <Products productType={contract.productType}>
          <Products.Product
            products={[
              ProductType.BAMBORA_DEVICE,
              ProductType.BAMBORA_DEVICE_SHORT_TERM,
              ProductType.BAMBORA_ONE,
              ProductType.BAMBORA_ONE_SHORT_TERM,
            ]}
          >
            <hr />
            <InvoiceToggle
              isEmailInvoice={isEmailInvoice}
              setIsEmailInvoice={setIsEmailInvoice}
              address={contract.contractData.address}
              invoiceAddress={invoiceAddress}
              setInvoiceAddress={setInvoiceAddress}
              hasSameInvoiceAddress={hasSameInvoiceAddress}
              setHasSameInvoiceAddress={setHasSameInvoiceAddress}
              primary={primary}
              invoiceStatus={invoiceStatus}
              setInvoiceStatus={setInvoiceStatus}
              invoiceEmail={invoiceEmail}
              setInvoiceEmail={setInvoiceEmail}
              setCodeWarning={setCodeWarning}
              code={code}
              setCode={setCode}
              latestVerifiedInvoiceEmail={latestVerifiedInvoiceEmail}
              formContainer={formContainer}
            />
          </Products.Product>
        </Products>

        <div
          style={{
            padding: "60px 0 0 0",
          }}
        />
        <ButtonPaneWithLabel
          label="Is a license required to run your business?"
          value={data.licensedEntity}
          onChange={(value) => {
            setData((prev) => ({
              ...prev,
              licensedEntity: value ?? false,
            }));
          }}
          small
          buttons={[
            {
              text: "Yes",
              active: data.licensedEntity === true,
              data: true,
            },
            {
              text: "No",
              active: data.licensedEntity === false,
              data: false,
            },
          ]}
          validators={[new RequiredValidator("Required to answer")]}
        />

        <div className="small m-bottom-10">
          <InfoBox>
            <T>
              Examples of businesses that require a license are: dentists, taxis
              and casinos.
            </T>
          </InfoBox>
        </div>

        <AnimateHeightMotion presence>
          {data.licensedEntity && (
            <Section highlighted>
              <TextInput
                label="Name of the licensing body"
                value={data.licensingBodyName}
                onChange={(value) =>
                  setData((prev) => ({
                    ...prev,
                    licensingBodyName: value,
                  }))
                }
                validators={[
                  new RequiredValidator("Name of licensing body is required"),
                ]}
              />
            </Section>
          )}
        </AnimateHeightMotion>

        <div className="m-top-40">
          <AnimateHeight name={isError ? "error" : ""}>
            {isError ? (
              isEmailInvoice === InvoiceType.EMAIL && !code ? (
                <GenericError>
                  <T>
                    Something went wrong. Seems like we are missing the
                    verfication code to the invoice email? Try adding the code
                    and make another try.
                  </T>
                </GenericError>
              ) : (
                <GenericError />
              )
            ) : (
              <div />
            )}
          </AnimateHeight>
        </div>

        <div>
          <AnimateHeight name={codeWarning ? "warn" : "no-warn"}>
            <div>
              {codeWarning ? (
                <ErrorBox relative>
                  <T>
                    You must confirm the invoice email by entering the requested
                    code
                  </T>
                  <p />
                </ErrorBox>
              ) : (
                <div />
              )}
            </div>
          </AnimateHeight>
        </div>

        <div className="m-top-20">
          <StoryContinueButton
            type="submit"
            disabled={access === Access.VIEW}
            isLoading={isLoading}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
