import { QueryOptions } from "@tanstack/react-query";
import { API } from "../network/API";
import { KlarnaToken, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const KLARNA_SCRIPT_URI = `https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js`;

interface KlarnaInitResponse {
  state: KlarnaState;
  clientToken: KlarnaToken;
}

export enum OwnedBy {
  PERSON = "PERSON",
  COMPANY = "COMPANY",
}

export interface KlarnaError {
  category: string;
  message?: string;
  reason?: Error;
}

export enum KlarnaState {
  PROCESSING = "PROCESSING",
  CONSUMER_INPUT_NEEDED = "CONSUMER_INPUT_NEEDED",
  ABORTED = "ABORTED",
  EXCEPTION = "EXCEPTION",
  FINISHED = "FINISHED",
}

export interface KlarnaPollResponse {
  state: KlarnaState;
  clientToken?: KlarnaToken;
  accounts?: KlarnaAccount[];
}

export enum AccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export interface KlarnaAccount {
  iban?: string;
  accountName?: string;
  accountHolder?: string;
  accountType: AccountType;
  accountStatus:
    | "MATCH"
    | "NO_MATCH"
    | "NO_HOLDER"
    | "NO_IBAN"
    | "INVALID_TYPE"
    | "INVALID_CURRENCY";
}

export interface ThirdPartyAccount {
  ibanOrAccountNumber: string;
  accountHolder: string;
  swiftOrBic: string;
}

export interface KarRequest {
  bankAccount: string;
  owner: "PERSON" | "COMPANY";
}

export interface KarResult {
  success: boolean;
}

export enum BankAccountSource {
  BANK_STATEMENT = "BANK_STATEMENT",
  OFFICIAL_REGISTRY = "OFFICIAL_REGISTRY",
  PSD_LOOKUP = "PSD_LOOKUP",
  BANKGIRO = "BANKGIRO",
}

export const bankAccountSourceText = {
  [BankAccountSource.BANKGIRO]: "bankgiro",
  [BankAccountSource.OFFICIAL_REGISTRY]: "official registry",
  [BankAccountSource.PSD_LOOKUP]: "Klarna",
  [BankAccountSource.BANK_STATEMENT]: "bank statement",
};

export interface ConfirmedStatus {
  done: boolean;
  bankAccount?: string;
  source?: BankAccountSource;
  iban?: string;
  bic?: string;
}

enum WhitelistedEndpoint {
  GET_KLARNA_SDK = "getKlarnaSDK",
  GET_JS = "getKontonummerJs",
  GET_CONFIRMED_STATUS = "getConfirmedStatus",
  GET_CONFIRMED_STATUS_KEY = "getConfirmedStatusKey",
  FETCH_CONFIRMED_STATUS = "fetchConfirmedStatus",
  GET_THIRD_PARTY_ACCOUNT = "getThirdPartyAccount",
  GET_THIRD_PARTY_ACCOUNT_KEY = "getThirdPartyAccountKey",
  FETCH_THIRD_PARTY_ACCOUNT = "fetchThirdPartyAccount",
}

export const Bank = {
  [WhitelistedEndpoint.GET_KLARNA_SDK]: (): QueryOptions<any> => ({
    queryKey: ["klarna"],
    queryFn: () => {
      if (window.XS2A) {
        return Promise.resolve(window.XS2A);
      }

      if (process.env.REACT_APP_STATIC) {
        return window.scripts
          .importScript(KLARNA_SCRIPT_URI)
          .then(() => window.XS2A);
      }

      return window.scripts
        .importScript("/libs/xs2a-app-launcher.js")
        .then(() => window.XS2A);
    },
  }),

  initKlarna: (linkId: LinkId) =>
    API.post<KlarnaInitResponse>(`/api/merchant/${linkId}/klarna`),

  pollForClientAccounts: (linkId: LinkId) =>
    API.get<KlarnaPollResponse>(`/api/merchant/${linkId}/klarna`),

  deleteSession: (linkId: LinkId) =>
    API.delete(`/api/merchant/${linkId}/klarna`),

  selectAccount: (linkId: LinkId, iban: string) => {
    return API.post(`/api/merchant/${linkId}/klarna/bank-account`, {
      iban,
    });
  },

  requestKARVerification: (linkId: LinkId, request: KarRequest) =>
    API.post<KarResult>(`/api/merchant/bank/${linkId}/norway`, request),

  reqeustBankgiroVerification: (linkId: LinkId, bankAccount: string) =>
    API.post(`/api/merchant/bank/${linkId}/sweden`, {
      bankAccount,
    }),

  [WhitelistedEndpoint.GET_JS]: () => {
    if (window.kontonummer) {
      return Promise.resolve(window.kontonummer);
    }

    return window.scripts.importScript("/libs/kontonummer.min.js");
  },

  [WhitelistedEndpoint.GET_CONFIRMED_STATUS_KEY]: (linkId: LinkId) => {
    return ["bank-confirmed", linkId];
  },

  [WhitelistedEndpoint.FETCH_CONFIRMED_STATUS]: (
    linkId: LinkId
  ): QueryOptions<ConfirmedStatus> => ({
    queryKey: Bank.getConfirmedStatusKey(linkId),
    queryFn: () => Bank.getConfirmedStatus(linkId),
  }),

  [WhitelistedEndpoint.GET_CONFIRMED_STATUS]: (linkId: LinkId) =>
    API.get<ConfirmedStatus>(`/api/merchant/bank/${linkId}`),

  [WhitelistedEndpoint.GET_THIRD_PARTY_ACCOUNT]: (linkId: LinkId) =>
    API.get<ThirdPartyAccount>(`/api/merchant/bank/${linkId}/external`),

  [WhitelistedEndpoint.FETCH_THIRD_PARTY_ACCOUNT]: (
    linkId: LinkId
  ): QueryOptions<ThirdPartyAccount> => ({
    queryKey: Bank.getThirdPartyAccountKey(linkId),
    queryFn: () => Bank.getThirdPartyAccount(linkId),
  }),

  [WhitelistedEndpoint.GET_THIRD_PARTY_ACCOUNT_KEY]: (linkId: LinkId) => {
    return ["third-party-account", linkId];
  },

  saveThirdPartyAccount: (
    linkId: LinkId,
    thirdPartyAccount: ThirdPartyAccount
  ) => API.post(`/api/merchant/bank/${linkId}/external`, thirdPartyAccount),

  saveKarAccount: (linkId: LinkId, bankAccount: string, ownedBy: OwnedBy) => {
    return API.post(`/api/merchant/bank/${linkId}/norway`, {
      bankAccount,
      owner: ownedBy,
    });
  },

  // POST /api/merchant/{linkId}/bank-statement kan uploadas fler gånger så
  // länge som inte kontraktet inte är konfirmat
};

let bank: typeof Bank;

export function dataBank(access: Access) {
  if (!bank) {
    bank = getProxy(Bank, access, Object.values(WhitelistedEndpoint));
  }

  return bank;
}
