import React from "react";
import styles from "./SavedAccount.module.scss";
import {
  ConfirmedStatus,
  KlarnaAccount,
  bankAccountSourceText,
} from "../../../data/dataBank";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T } from "../../../components/translation/T";
import { Or } from "../../../components/or/Or";
import "./SavedAccount.module.scss";

interface Props {
  savedAccount: ConfirmedStatus | KlarnaAccount;
  next: () => void;
  disabled?: boolean;
}

// done: boolean;
// bankAccount?: string;
// source?: BankAccountSource;

// iban?: string;
// accountName?: string;
// accountHolder?: string;

export function isKlarna(
  savedAccount?: ConfirmedStatus | KlarnaAccount
): savedAccount is KlarnaAccount {
  if (!savedAccount) {
    return false;
  }

  return !((savedAccount as ConfirmedStatus).done !== undefined);
}

export const SavedAccount: React.FunctionComponent<Props> = ({
  savedAccount,
  next,
  disabled = false,
}) => {
  if (isKlarna(savedAccount)) {
    return (
      <>
        <SuccessBox relative>
          <T>
            A payout account has already been saved. You can either keep that
            one or change account.
          </T>
        </SuccessBox>

        <div className={styles.box}>
          <b>{savedAccount.accountName || savedAccount.iban}</b>
          <br />
          {savedAccount.accountHolder}
        </div>

        <div className="m-top-20">
          <Button block onClick={next} disabled={disabled}>
            <T>Continue</T>
          </Button>
        </div>
        <Or className="bg">
          <div>&nbsp;</div>
          <div></div>
        </Or>
      </>
    );
  }

  if (!(savedAccount as ConfirmedStatus).done) {
    return null;
  }

  return (
    <>
      <SuccessBox relative>
        <T>
          A payout account has already been saved. You can either keep that one
          or change account.
        </T>
      </SuccessBox>

      <div className={styles.box}>
        <T>Account</T>: <b>{savedAccount.bankAccount}</b>
        {savedAccount.source ? (
          <>
            <br />
            <T>Added by </T>
            {bankAccountSourceText[savedAccount.source]}
          </>
        ) : null}
      </div>

      <div className="m-top-20">
        <Button block onClick={next} disabled={disabled}>
          <T>Continue</T>
        </Button>
      </div>

      <Or className="bg">
        <div>&nbsp;</div>
        <div></div>
      </Or>
    </>
  );
};
