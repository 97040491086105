import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
  AssociateId,
  CompanyRegistrationId,
  ContractVersion,
  Country,
  Language,
  LegalEntityType,
  ProductType,
} from "../../data/models/ContractTypes";
import { contractState } from "../../state/contractState";
import i18n from "../../i18n";
import "./KlarnaTestPage.scss";
import { ViewerStatus } from "../../data/dataMerchant";

export const KLARNA_TEST_PAGE_URL = "/klarna-test";

interface Props {
  ready: boolean;
}

const Inner: React.FunctionComponent<Props> = ({ ready }) => {
  if (!ready) {
    return null;
  }

  return null;
};

export const KlarnaTestPage: React.FunctionComponent = () => {
  const setContractState = useSetRecoilState(contractState);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    let lang = Language.ENGLISH as string;
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      ({ lang } = Object.fromEntries(urlSearchParams.entries()));
    } catch (err) {}

    i18n.changeLanguage(lang);

    // TODO: update with Swedish data
    setContractState({
      productType: ProductType.BAMBORA_ONE,
      contractData: {
        mccIndustry: "Consulting, management and public relations services",
        version: 7 as ContractVersion,
        country: "SE" as Country,
        organizationNumber: "1231119747" as CompanyRegistrationId,
        companyName: "CONSULTING JERZY CIECIERSKI",
        legalEntityType: LegalEntityType.LIMITED,
        invoiceAddress: {
          street: "ul. Piwna 12/14",
          postalCode: "00-263",
          city: "Warszawa",
          countryCode: Country.SWEDEN,
        },
        address: {
          street: "ul. Piwna 12/14",
          postalCode: "00-263",
          city: "Warszawa",
          countryCode: Country.SWEDEN,
        },
      },
      enabledFeatures: [],
      confirmedContract: false,
      contractViewer: {
        associateId: "" as AssociateId,
        language: Language.ENGLISH,
        status: ViewerStatus.INFORMATION,
      },
    });
    setReady(true);
  }, [setContractState]);

  return (
    <section className="klarna-test-page">
      <article>
        <Inner ready={ready} />
      </article>
    </section>
  );
};
