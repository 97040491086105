import React, { ReactNode, useEffect, useRef } from "react";
import cx from "classnames";
import { useMutation } from "@tanstack/react-query";
import styles from "./StateSuccess.module.scss";
import uploadStyles from "./Klarna.module.scss";
import {
  dataBank,
  KlarnaAccount as KlarnaAccountInterface,
} from "../../../../data/dataBank";
import { View } from "../Bank";
import { T } from "../../../../components/translation/T";
import { t } from "i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { NumberedItem } from "../../../../components/numberedItem/NumberedItem";
import { KlarnaAccount } from "./KlarnaAccount";
import { Upload } from "./Upload";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Country, LinkId } from "../../../../data/models/ContractTypes";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { GenericError } from "../../../../components/Errors/GenericError";
import { useRecoilState, useSetRecoilState } from "recoil";
import { bankState } from "../../../../state/bankState";
import { routeState } from "../../../../state/routeState";
import { Access } from "../../../../data/proxy";

interface Props {
  accounts: KlarnaAccountInterface[];
  onChangeBank: () => void;
  onViewChange: (view: View) => void;
  setSelectedAccount: (account: KlarnaAccountInterface) => void;
  selectedAccount?: KlarnaAccountInterface;
  next: () => void;
  linkId: LinkId;
  country: Country;
  disabled?: boolean;
  buttonName?: ReactNode;
}

const EmptyAccounts: React.FunctionComponent<
  Pick<Props, "onChangeBank" | "onViewChange" | "country" | "disabled">
> = ({ onChangeBank, onViewChange, country, disabled }) => {
  const linkToBankgiro = country === Country.SWEDEN;

  const button = linkToBankgiro ? (
    <Button
      block
      ghost
      disabled={disabled}
      onClick={() => {
        onViewChange(View.BANKGIRO);
      }}
    >
      <T>Enter your payout account</T>
    </Button>
  ) : (
    <Button
      block
      ghost
      disabled={disabled}
      onClick={() => {
        onViewChange(View.BANK_STATEMENT);
      }}
    >
      <T>Upload bank statement</T>
    </Button>
  );

  return (
    <>
      <h4>
        <T>Available accounts</T>
      </h4>
      {t("We couldn't find any valid accounts")}{" "}
      <span className="text-large">🤔</span>. {t("Instead you can")}
      <NumberedItem index={1} color="#e5f2f4">
        {t("Choose another bank, or")}
      </NumberedItem>
      <NumberedItem index={2} color="#e5f2f4">
        {t(
          country === Country.SWEDEN
            ? "Manually add a payout account"
            : "Upload a bank statement that confirms the account."
        )}
      </NumberedItem>
      <div className="m-top-20 tablet-columns">
        <div className="m-top-10">
          <Button block onClick={onChangeBank} disabled={disabled}>
            <T>Choose another bank</T>
          </Button>
        </div>
        <div className="m-top-10">{button}</div>
      </div>
    </>
  );
};

export const StateSuccess: React.FunctionComponent<Props> = ({
  accounts,
  onChangeBank,
  onViewChange,
  setSelectedAccount,
  selectedAccount,
  next,
  linkId,
  country,
  disabled = false,
  buttonName = <T>Continue</T>,
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const linkToBankgiro = country === Country.SWEDEN;
  const setBankState = useSetRecoilState(bankState);
  const [{ access }, setRoutingProps] = useRecoilState(routeState);
  const {
    mutate: onSave,
    isLoading: isSaving,
    isError,
    reset,
  } = useMutation(
    () => dataBank(access).selectAccount(linkId, selectedAccount?.iban ?? ""),
    {
      onSuccess: () => {
        if (selectedAccount) {
          setBankState({
            account: selectedAccount,
          });
        }

        setRoutingProps((prev) => ({
          ...prev,
          payoutAccountHasBeenAdded: true,
        }));
        next();
      },
    }
  );

  useEffect(() => {
    reset();
  }, [selectedAccount, reset]);

  const buttonToAlternativeMethod = linkToBankgiro ? (
    <div className={cx("text-small", "text-passive", uploadStyles.upload)}>
      <T>Can't find your account? You can add your account by</T>{" "}
      <Button
        text
        disabled={disabled}
        className="text-small"
        onClick={() => onViewChange(View.BANKGIRO)}
      >
        <T>entering your bank account</T>
      </Button>
      .
    </div>
  ) : (
    <Upload onViewChange={onViewChange} disabled={disabled || isSaving}>
      <T>Can't find your account? You can add your account by</T>
    </Upload>
  );

  if (!accounts.length) {
    return (
      <EmptyAccounts
        onChangeBank={onChangeBank}
        onViewChange={onViewChange}
        country={country}
        disabled={disabled || isSaving}
      />
    );
  }

  return (
    <>
      <h4>
        <T>Available accounts</T>
      </h4>
      <div className={styles.wrapper}>
        <ul>
          {accounts.map((account) => (
            <KlarnaAccount
              selected={
                !!(account.iban && selectedAccount?.iban === account.iban)
              }
              setSelectedAccount={setSelectedAccount}
              account={account}
              key={`${account.iban}${account.accountName}`}
              disabled={disabled}
              isLoading={isSaving}
            />
          ))}
        </ul>

        <div className="center m-top-20">
          <Button text onClick={onChangeBank} disabled={disabled || isSaving}>
            <T>No, wait, let me choose another bank</T>
          </Button>
        </div>
      </div>

      {buttonToAlternativeMethod}

      <Form
        name="klarna-accounts"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }
          onSave();
        }}
      >
        <HiddenInput
          label="Missing account"
          value={!!selectedAccount ? true : undefined}
          validators={[new RequiredValidator("You must choose an account")]}
          scrollToRef={ref}
        />

        <div className={styles.error}>
          <AnimateHeight name={isError ? "error" : ""}>
            <div>{isError ? <GenericError /> : <div />}</div>
          </AnimateHeight>
        </div>

        <StoryContinueButton
          type="submit"
          isLoading={isSaving}
          disabled={access === Access.VIEW || disabled}
          className="m-top-30"
        >
          {buttonName}
        </StoryContinueButton>
      </Form>
    </>
  );
};
