import React, { ReactNode } from "react";
import styles from "./Klarna.module.scss";
import { Status } from "../../../../data/types";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Spinner } from "../../../../components/spinner/Spinner";
import { View } from "../Bank";
import { KlarnaAccount as KlarnaAccountInterface } from "../../../../data/dataBank";
import { StateDefault } from "./StateDefault";
import { StateSuccess } from "./StateSuccess";
import { T } from "../../../../components/translation/T";
import { Country, LinkId } from "../../../../data/models/ContractTypes";

interface Props {
  accounts: KlarnaAccountInterface[];
  status: Status;
  onRetry: () => void;
  onChangeBank: () => void;
  onViewChange: (view: View) => void;
  setSelectedAccount: (account: KlarnaAccountInterface) => void;
  selectedAccount?: KlarnaAccountInterface;
  next: () => void;
  linkId: LinkId;
  country: Country;
  disabled?: boolean;
  buttonName?: ReactNode;
}

export const Klarna: React.FunctionComponent<Props> = ({
  status,
  accounts,
  onChangeBank,
  onRetry,
  onViewChange,
  setSelectedAccount,
  selectedAccount,
  next,
  linkId,
  country,
  disabled = false,
  buttonName = <T>Continue</T>,
}) => {
  return (
    <div className={styles.klarnaAccounts}>
      {status === Status.DEFAULT && (
        <StateDefault
          onRetry={onRetry}
          onViewChange={onViewChange}
          country={country}
          disabled={disabled}
        />
      )}
      {status === Status.SUCCESS && (
        <StateSuccess
          country={country}
          linkId={linkId}
          next={next}
          onViewChange={onViewChange}
          accounts={accounts}
          onChangeBank={onChangeBank}
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          disabled={disabled}
          buttonName={buttonName}
        />
      )}
      {status === Status.ERROR && (
        <div className="center">
          <p>
            <b>
              <T>Ooh, noo!</T>
            </b>{" "}
            <T>Something went wrong.</T>
          </p>
          <Button onClick={onRetry}>
            <T>Try again?</T>
          </Button>
        </div>
      )}
      {status === Status.PENDING && <Spinner />}
    </div>
  );
};
