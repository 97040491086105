import { atom } from "recoil";
import { KlarnaAccount } from "../data/dataBank";

export interface BankState {
  account?: KlarnaAccount;
}

export const bankState = atom<BankState>({
  key: "bankState",
  default: {},
});
