import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { StoryStepProps } from "../../../components/story/Story";
import { T } from "../../../components/translation/T";
import {
  BankAccountSource,
  ConfirmedStatus,
  dataBank,
  KlarnaAccount,
  KlarnaState,
} from "../../../data/dataBank";
import { KlarnaToken } from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { useLinkId } from "../../../hooks/useLinkId";
import { BankStatement } from "./views/BankStatement";
import { Klarna } from "./views/Klarna";
import { useSuspendedQueries } from "../../../hooks/useSuspendedQueries";
import { dataAssociates } from "../../../data/dataAssociates";
import { Bankgiro } from "./views/Bankgiro";
import { useCountry } from "../../../hooks/useCountry";
import { useRecoilState } from "recoil";
import { bankState } from "../../../state/bankState";
import { SavedAccount } from "./SavedAccount";
import { useAccess } from "../../../hooks/useAccess";
import styles from "./Bank.module.scss";
import { Access } from "../../../data/proxy";

const isDev = "development" === process.env.NODE_ENV;
const POLLING_TIMER = 3000;
const MAX_NO_OF_ATTEMPTS = 5;

export enum View {
  KLARNA = "KLARNA",
  BANK_STATEMENT = "BANK_STATEMENT",
  BANKGIRO = "BANKGIRO",
}

export const Bank: React.FunctionComponent<StoryStepProps> = ({
  next,
  pageRef,
}) => {
  const access = useAccess();
  const linkId = useLinkId();
  const [{ data: sdk }, { data: associates }, { data: externalAccount }] =
    useSuspendedQueries({
      queries: [
        dataBank(access).getKlarnaSDK(),
        dataAssociates(access).fetchAssociates(linkId),
        dataBank(access).fetchConfirmedStatus(linkId),
      ],
    });

  const [savedLocalAccount, setSavedLocalAccount] = useRecoilState(bankState);
  const [savedExternalAccount, setSavedExternalAccount] =
    useState<ConfirmedStatus>(externalAccount);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [clientToken, setClientToken] = useState<KlarnaToken>();
  const [accounts, setAccounts] = useState<KlarnaAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<KlarnaAccount>();
  const [view, setView] = useState<View>(
    (savedExternalAccount as ConfirmedStatus).source?.toString() ===
      BankAccountSource.BANKGIRO.toString()
      ? View.BANKGIRO
      : View.KLARNA
  );

  const noOfRetries = useRef<number>(0);
  const timer = useRef<number>();
  const ongoing = useRef<boolean>(false);
  const country = useCountry();

  const requestToken = useCallback(() => {
    // we should reset any session saved bank accounts
    setSavedLocalAccount({});
    setSavedExternalAccount({ done: false });
    ongoing.current = true;
    dataBank(access)
      .initKlarna(linkId)
      .then((resp) => setClientToken(resp.clientToken))
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [linkId, access, setSavedLocalAccount]);

  const deleteSession = useCallback(
    (status: Status) => {
      return new Promise<void>((resolve) => {
        setClientToken(undefined);
        window.clearTimeout(timer.current);
        const reset = () => {
          ongoing.current = false;
          setStatus(status);
          resolve();
        };

        dataBank(access).deleteSession(linkId).then(reset).catch(reset);
      });
    },
    [linkId, access]
  );

  useEffect(() => {
    return () => {
      if (ongoing.current) {
        deleteSession(Status.DEFAULT);
      }
    };
  }, [deleteSession]);

  const pollForClientAccounts = useCallback(() => {
    if (!clientToken) {
      return;
    }

    dataBank(access)
      .pollForClientAccounts(linkId)
      .then((data) => {
        noOfRetries.current = 0;

        if (
          data.state === KlarnaState.ABORTED ||
          data.state === KlarnaState.EXCEPTION
        ) {
          deleteSession(Status.ERROR);
          return;
        }

        if (data.state === KlarnaState.FINISHED) {
          setStatus(Status.SUCCESS);
          setAccounts(data.accounts || []);
          const accountWithHolderNameMatch = data.accounts?.filter(
            (account) => account.accountStatus === "MATCH"
          )[0];

          if (accountWithHolderNameMatch) {
            setSelectedAccount(accountWithHolderNameMatch);
          }
          return;
        }

        timer.current = window.setTimeout(pollForClientAccounts, POLLING_TIMER);
      })
      .catch(() => {
        if (noOfRetries.current === MAX_NO_OF_ATTEMPTS - 1) {
          deleteSession(Status.ERROR);
          noOfRetries.current = 0;
          return;
        }

        noOfRetries.current++;
        timer.current = window.setTimeout(pollForClientAccounts, POLLING_TIMER);
      });
  }, [clientToken, access, deleteSession, linkId]);

  const onRetry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(requestToken, 300);
  }, [requestToken]);

  const onChangeBank = useCallback(() => {
    setStatus(Status.PENDING);
    deleteSession(Status.PENDING).then(() => setTimeout(requestToken, 300));
  }, [requestToken, deleteSession]);

  useEffect(() => {
    if (!clientToken) {
      return;
    }

    if (isDev) {
      pollForClientAccounts();
      return;
    }

    try {
      sdk.configure({
        appearenceMode: "light",
        autoClose: true,
        hideTransitionOnFlowEnd: true,
        openPoliciesInNewWindow: true,
        psd2RedirectMethod: "newWindow",
        skipInitialLoader: true,
        theme: "default",
        unfoldConsentDetails: false,
        onLoad: () => {
          console.log("onLoad  called");
        },
        onReady: () => {
          console.log("onReady called");
        },
        onAbort: () => {
          console.log("onAbort called");
        },
        onError: (error: any) => {
          console.log("onError called", error);
        },
        onFinished: () => {
          console.log("onFinished called");
        },
        onClose: () => {
          console.log("onClose called");
        },
      });

      // Start the flow with the client_token from the flow response.
      sdk.startFlow(clientToken, {
        onFinished: pollForClientAccounts,
        onAbort: () => deleteSession(Status.DEFAULT),
        onError: (error: any) => {
          deleteSession(Status.ERROR);
          console.error(
            "onError: something bad happened during the flow.",
            error
          );
        },
      });
    } catch (e) {
      // Handle error that happened while opening the App
      deleteSession(Status.ERROR);
      console.error("klarna error", e);
    }
  }, [clientToken, deleteSession, sdk, pollForClientAccounts]);

  const info = useMemo(() => {
    if (!savedLocalAccount.account && !savedExternalAccount.done) {
      return null;
    }

    return (
      <SavedAccount
        disabled={access === Access.VIEW}
        next={next}
        savedAccount={savedLocalAccount.account || savedExternalAccount}
      />
    );
  }, [savedLocalAccount, next, access, savedExternalAccount]);

  return (
    <>
      <div className={styles.bank} ref={pageRef}>
        <h2>
          <T>Payout account</T>
        </h2>
        <p>
          <T>
            We need you to add a payout account that will be used for your
            transactions.
          </T>
        </p>
        <div className="m-top-20">
          <AnimateHeight name={info ? "one" : "two"}>
            <div>{info}</div>
          </AnimateHeight>
        </div>
      </div>

      <AnimateHeight name={`${status}${view}`}>
        <div>
          {view === View.BANK_STATEMENT && (
            <BankStatement
              onViewChange={setView}
              linkId={linkId}
              next={next}
              associates={associates}
              disabled={access === Access.VIEW}
            />
          )}
          {view === View.BANKGIRO && (
            <Bankgiro
              onViewChange={setView}
              linkId={linkId}
              next={next}
              bankAccount={savedExternalAccount.bankAccount}
              disabled={access === Access.VIEW}
            />
          )}
          {view === View.KLARNA && (
            <Klarna
              linkId={linkId}
              next={next}
              accounts={accounts}
              onViewChange={setView}
              onRetry={onRetry}
              onChangeBank={onChangeBank}
              status={status}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              country={country}
              disabled={access === Access.VIEW}
            />
          )}
        </div>
      </AnimateHeight>
    </>
  );
};
