import React from "react";
import cx from "classnames";
import styles from "./Klarna.module.scss";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { View } from "../Bank";
import { ReactNode } from "react";
import { T } from "../../../../components/translation/T";

interface Props {
  onViewChange: (view: View) => void;
  children: string | ReactNode;
  disabled?: boolean;
}

export const Upload: React.FunctionComponent<Props> = ({
  onViewChange,
  children,
  disabled = false,
}) => (
  <div className={cx("text-small", "text-passive", styles.upload)}>
    {children}{" "}
    <Button
      disabled={disabled}
      text
      className="text-small"
      onClick={() => onViewChange(View.BANK_STATEMENT)}
    >
      <T>uploading a bank statement</T>
    </Button>
    .
  </div>
);
