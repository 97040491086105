import React from "react";
import { Route, useNavigate } from "react-router-dom";
import { PayoutAccountCollection } from "./PayoutAccountCollection";
import { Story } from "../../components/story/Story";
import { useRecoilValue } from "recoil";
import { useViewerAsAssociate } from "../../hooks/useViewerAsAssociate";
import { contractState } from "../../state/contractState";
import { dataAssociates, isSelectedSignee } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { useSuspendedQuery } from "../../hooks/useSuspendedQuery";
import { useTranslation } from "react-i18next";
import { Sign } from "./Sign/Sign";
import { Access } from "../../data/proxy";

export const PAYOUT_ACCOUNT_URL = "/:linkId/add-payout-account/";

const ROUTES = {
  FIRST_STEP: "",
  SECOND_STEP: "sign",
};

interface Props {}

export const PayoutAccountCollectionStory: React.FunctionComponent<
  Props
> = () => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const { data: associates } = useSuspendedQuery(
    dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId)
  );
  const navigate = useNavigate();
  const contract = useRecoilValue(contractState);
  const viewer = useViewerAsAssociate(associates, contract.contractViewer);

  if (!isSelectedSignee(viewer) || !!viewer?.signatory?.signed) {
    return <PayoutAccountCollection />;
  }

  return (
    <Story
      headerText={t("Add payout account")}
      routes={ROUTES}
      baseRoute={PAYOUT_ACCOUNT_URL}
    >
      <Route
        index
        element={
          <PayoutAccountCollection next={() => navigate(ROUTES.SECOND_STEP)} />
        }
      />
      <Route path={ROUTES.SECOND_STEP} element={<Sign />} />
    </Story>
  );
};
