import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactNode, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Kontonummer from "kontonummer";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Card } from "../../../../components/cards/Card";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Form } from "../../../../components/form/Form";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { T } from "../../../../components/translation/T";
import { dataBank } from "../../../../data/dataBank";
import { LinkId } from "../../../../data/models/ContractTypes";
import { Validity } from "../../../../data/types";
import { View } from "../Bank";
import { useRecoilState } from "recoil";
import { routeState } from "../../../../state/routeState";
import { Access } from "../../../../data/proxy";
import styles from "./Bankgiro.module.scss";
import { BankValidatorMinLength } from "../../../../components/form/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../../components/form/validators/BankValidatorMaxLength";
import { WarningBox } from "../../../../components/boxes/WarningBox";

interface Props {
  linkId: LinkId;
  onViewChange: (view: View) => void;
  next: () => void;
  bankAccount?: string;
  iban?: string;
  disabled?: boolean;
  buttonName?: ReactNode;
}

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 10;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 5;

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}

export const Bankgiro: React.FunctionComponent<Props> = ({
  linkId,
  onViewChange,
  next,
  bankAccount: originalAccount = "",
  iban = "",
  disabled = false,
  buttonName = <T>Continue</T>,
}) => {
  const [bankAccount, setBankAccount] = useState<string>("");
  const [clearing, setClearing] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);
  const { t } = useTranslation();
  const [{ access }, setRoutingProps] = useRecoilState(routeState);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const showBankName = useMemo(() => {
    return (
      !!info &&
      cleanNumber(clearing).length + cleanNumber(bankAccount).length >=
        MIN_LENGTH_CLEARING + MIN_LENGTH_ACCOUNT
    );
  }, [info, clearing, bankAccount]);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError,
    reset,
  } = useMutation(
    () => {
      let clearingNumber = cleanNumber(clearing);
      if (clearing.length > 4) {
        clearingNumber = clearingNumber.slice(0, -1);
      }

      return dataBank(access).reqeustBankgiroVerification(
        linkId,
        clearingNumber + cleanNumber(bankAccount)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          dataBank(access).getConfirmedStatusKey(linkId)
        );
        setRoutingProps((prev) => ({
          ...prev,
          payoutAccountHasBeenAdded: true,
        }));
        next();
      },
    }
  );

  return (
    <Form
      name="bank-account"
      onSubmit={(_, form) => {
        if (!form.isValid) {
          return;
        }

        if (!info?.valid && !hasShowedWarning) {
          setHasShowedWarning(true);
          return;
        }

        onSave();
      }}
    >
      <Card>
        <Trans t={t}>
          The preferred and fastest way verify your bank account is to{" "}
          <Button
            className={styles.link}
            text
            onClick={() => onViewChange(View.KLARNA)}
          >
            log into your company's bank
          </Button>{" "}
          and select one of the accounts. If your bank is not (yet) supported
          you are able to add the account number below. The account will be{" "}
          <u>verified by bankgiro</u>.
        </Trans>

        <p>
          <b>Exempel:</b> 3300 7810251111
        </p>
        <div className="m-top-40">
          <div className="tablet-columns">
            <div>
              <TextInput
                name="clearing"
                label="Clearing number"
                value={clearing}
                disabled={disabled}
                validators={[
                  new RequiredValidator("Required"),
                  new BankValidatorMinLength(
                    MIN_LENGTH_CLEARING,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                  new BankValidatorMaxLength(
                    MAX_LENGTH_CLEARING,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setClearing(value);
                  setHasShowedWarning(false);
                  try {
                    const info = Kontonummer.parse(value, bankAccount || "", {
                      mode: "strict",
                    });
                    setInfo(info);
                  } catch (err) {
                    setInfo(null);
                  }
                }}
              />
            </div>
            <div>
              <TextInput
                name="bankAccount"
                label="Account number"
                value={bankAccount}
                disabled={disabled}
                validators={[
                  new RequiredValidator("Required"),
                  new BankValidatorMinLength(
                    MIN_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                  new BankValidatorMaxLength(
                    MAX_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setBankAccount(value);
                  setHasShowedWarning(false);
                  try {
                    const info = Kontonummer.parse(clearing, value, {
                      mode: "strict",
                    });
                    setInfo(info);
                  } catch (err) {
                    setInfo(null);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <AnimateHeight name={showBankName ? "account" : ""}>
          {info && showBankName ? (
            <div className={styles.info}>
              <Beacon className="mini" validity={Validity.VALID} />
              <b>{info.bankName}</b> - {`${info.format("pretty")} `}
            </div>
          ) : (
            <div />
          )}
        </AnimateHeight>
      </Card>

      <AnimateHeight name={hasShowedWarning ? "warning" : ""}>
        {hasShowedWarning ? (
          <div className={styles.warning}>
            <WarningBox relative>
              <Trans>
                Bank accounts are sometimes hard to verify. In this case, we are{" "}
                <b>unable to verify</b> the entered account number. Please check
                the account number again. If it looks good, you may proceed.
              </Trans>
            </WarningBox>
          </div>
        ) : (
          <div />
        )}
      </AnimateHeight>

      <div className={styles.error}>
        <AnimateHeight name={isError ? "error" : ""}>
          <div>{isError ? <GenericError /> : <div />}</div>
        </AnimateHeight>
      </div>

      <StoryContinueButton
        type="submit"
        isLoading={isSaving}
        disabled={access === Access.VIEW || disabled}
        className="m-top-30"
        ghost={!!originalAccount}
      >
        {buttonName}
      </StoryContinueButton>
    </Form>
  );
};
