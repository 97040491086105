import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { dataAssociates, isPrimary } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { contractState } from "../../state/contractState";
import { T } from "../../components/translation/T";
import { useViewerAsAssociate } from "../../hooks/useViewerAsAssociate";
import { Trans, useTranslation } from "react-i18next";
import { Logo } from "../../components/images/Logo";
import {
  BankAccountSource,
  ConfirmedStatus,
  dataBank,
  KlarnaAccount,
  KlarnaState,
} from "../../data/dataBank";
import { KlarnaToken } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { useCountry } from "../../hooks/useCountry";
import { useSuspendedQueries } from "../../hooks/useSuspendedQueries";
import { View } from "../DataCollection/Bank/Bank";
import { Associate } from "../../data/dataMerchant";
import styles from "./PayoutAccountCollection.module.scss";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { BankStatement } from "../DataCollection/Bank/views/BankStatement";
import { Bankgiro } from "../DataCollection/Bank/views/Bankgiro";
import { Klarna } from "../DataCollection/Bank/views/Klarna";
import { TI } from "../../i18n";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { NumberedItem } from "../../components/numberedItem/NumberedItem";
import { StoryStepProps } from "../../components/story/Story";
import { StoryPage } from "../../components/story/StoryPage";
import { LanguageSwitch } from "../../components/languageSwitch/LanguageSwitch";
import { Access } from "../../data/proxy";

const isDev = "development" === process.env.NODE_ENV;
const POLLING_TIMER = 3000;
const MAX_NO_OF_ATTEMPTS = 5;

export const PayoutAccountCollection: React.FunctionComponent<
  Partial<StoryStepProps>
> = (props) => {
  return <PayoutAccountCollectionKlarna {...props} />;
};

export const PayoutAccountCollectionKlarna: React.FunctionComponent<
  Partial<StoryStepProps>
> = ({ next }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const linkId = useLinkId();
  const [{ data: sdk }, { data: associates }, { data: bank }] =
    useSuspendedQueries({
      queries: [
        dataBank(Access.VIEW_AND_EDIT).getKlarnaSDK(),
        dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId),
        dataBank(Access.VIEW_AND_EDIT).fetchConfirmedStatus(linkId),
      ],
    });

  const contract = useRecoilValue(contractState);
  const { companyName } = contract.contractData;
  const viewer = useViewerAsAssociate(associates, contract.contractViewer);
  const primary = (associates as Associate[]).find((associate) =>
    isPrimary(associate)
  );
  const primaryName = `${primary?.contact.firstName} ${primary?.contact.lastName}`;
  const viewerIsPrimary = viewer?.associateId === primary?.associateId;
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [clientToken, setClientToken] = useState<KlarnaToken>();
  const [accounts, setAccounts] = useState<KlarnaAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<KlarnaAccount>();
  const [view, setView] = useState<View>(
    (bank as ConfirmedStatus).source?.toString() ===
      BankAccountSource.BANKGIRO.toString()
      ? View.BANKGIRO
      : View.KLARNA
  );
  const noOfRetries = useRef<number>(0);
  const timer = useRef<number>();
  const country = useCountry();
  const [confirmed, setConfirmed] = useState<Status>(Status.DEFAULT);

  const onBankgiroSubmit = useCallback(() => {
    if (next) {
      next();
    } else {
      setConfirmed(Status.SUCCESS);
    }
  }, [next]);

  const onBankstatementSubmit = useCallback(() => {
    if (next) {
      next();
    } else {
      setConfirmed(Status.SUCCESS);
    }
  }, [next]);

  const onNext = useCallback(() => {
    if (selectedAccount) {
      if (next) {
        next();
      } else {
        setConfirmed(Status.SUCCESS);
      }
    } else {
      setConfirmed(Status.ERROR);
    }
  }, [selectedAccount, next]);

  const requestToken = useCallback(() => {
    dataBank(Access.VIEW_AND_EDIT)
      .initKlarna(linkId)
      .then((resp) => setClientToken(resp.clientToken))
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [linkId]);

  const deleteSession = useCallback(
    (status: Status) => {
      return new Promise<void>((resolve) => {
        setClientToken(undefined);
        const reset = () => {
          setStatus(status);
          resolve();
        };

        dataBank(Access.VIEW_AND_EDIT)
          .deleteSession(linkId)
          .then(reset)
          .catch(reset);
      });
    },
    [linkId]
  );

  const pollForClientAccounts = useCallback(() => {
    if (!clientToken) {
      return;
    }

    dataBank(Access.VIEW_AND_EDIT)
      .pollForClientAccounts(linkId)
      .then((data) => {
        noOfRetries.current = 0;

        if (
          data.state === KlarnaState.ABORTED ||
          data.state === KlarnaState.EXCEPTION
        ) {
          deleteSession(Status.ERROR);
          return;
        }

        if (data.state === KlarnaState.FINISHED) {
          setStatus(Status.SUCCESS);
          setAccounts(data.accounts || []);
          const accountWithHolderNameMatch = data.accounts?.filter(
            (account) => account.accountStatus === "MATCH"
          )[0];

          if (accountWithHolderNameMatch) {
            setSelectedAccount(accountWithHolderNameMatch);
          }
          return;
        }

        timer.current = window.setTimeout(pollForClientAccounts, POLLING_TIMER);
      })
      .catch(() => {
        if (noOfRetries.current === MAX_NO_OF_ATTEMPTS - 1) {
          deleteSession(Status.ERROR);
          noOfRetries.current = 0;
          return;
        }

        noOfRetries.current++;
        timer.current = window.setTimeout(pollForClientAccounts, POLLING_TIMER);
      });
  }, [clientToken, deleteSession, linkId]);

  const onRetry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(requestToken, 300);
  }, [requestToken]);

  const onChangeBank = useCallback(() => {
    setStatus(Status.PENDING);
    deleteSession(Status.PENDING).then(() => setTimeout(requestToken, 300));
  }, [requestToken, deleteSession]);

  useEffect(() => {
    if (!clientToken) {
      return;
    }

    if (isDev) {
      pollForClientAccounts();
      return;
    }

    try {
      sdk.configure({
        appearenceMode: "light",
        autoClose: true,
        hideTransitionOnFlowEnd: true,
        openPoliciesInNewWindow: true,
        psd2RedirectMethod: "newWindow",
        skipInitialLoader: true,
        theme: "default",
        unfoldConsentDetails: false,
        onLoad: () => {
          console.log("onLoad called");
        },
        onReady: () => {
          console.log("onReady called");
        },
        onAbort: () => {
          console.log("onAbort called");
        },
        onError: (error: any) => {
          console.log("onError called", error);
        },
        onFinished: () => {
          console.log("onFinished called");
        },
        onClose: () => {
          console.log("onClose called");
        },
      });

      // Start the flow with the client_token from the flow response.
      sdk.startFlow(clientToken, {
        onFinished: pollForClientAccounts,
        onAbort: () => deleteSession(Status.DEFAULT),
        onError: (error: any) => {
          deleteSession(Status.ERROR);
          console.error(
            "onError: something bad happened during the flow.",
            error
          );
        },
      });
    } catch (e) {
      // Handle error that happened while opening the App
      deleteSession(Status.ERROR);
      console.error("klarna error", e);
    }
  }, [clientToken, deleteSession, sdk, pollForClientAccounts]);

  return (
    <StoryPage ref={ref}>
      <div className={styles.wrapper}>
        <div className="logo-wrapper">
          <Logo />
        </div>

        <div className="m-top-30">
          <LanguageSwitch />
        </div>

        <div className={styles.header}>
          <h2>
            <T
              id="Hi, {{name}}!"
              options={{
                name: `${viewer?.contact.firstName} ${viewer?.contact.lastName}`,
              }}
            />
          </h2>
        </div>

        <div className="m-top-30">
          {viewerIsPrimary ? (
            <p>
              <Trans t={t}>
                We're pleased that you've chosen Worldline as your payment
                service. However, we couldn't verify the payout account that was
                added. We will need you to choose another one.
              </Trans>
            </p>
          ) : (
            <>
              <p>
                <Trans t={t}>
                  <strong>{{ companyName } as TI}</strong> has been registered
                  for Worldline payment services by{" "}
                  <strong>{{ primaryName } as TI}</strong>.
                </Trans>
              </p>
              <div className="m-top-30">
                <NumberedItem index={1} color="#e5f2f4">
                  <Trans t={t}>
                    Perhaps {{ primaryName } as TI} has assigned you to add the
                    payout account
                  </Trans>
                </NumberedItem>
                <NumberedItem index={2} color="#e5f2f4">
                  <Trans t={t}>
                    Or we couldn't verify the previous payout account that was
                    added. We will need you to choose another one.
                  </Trans>
                </NumberedItem>
              </div>
            </>
          )}
        </div>

        <div className="m-top-40">
          <AnimateHeight name={`${confirmed}${status}${view}`}>
            <div>
              {confirmed !== Status.SUCCESS && view === View.BANK_STATEMENT && (
                <BankStatement
                  onViewChange={setView}
                  linkId={linkId}
                  next={onBankstatementSubmit}
                  associates={associates}
                  buttonName={<T>Confirm payout account</T>}
                />
              )}
              {confirmed !== Status.SUCCESS && view === View.BANKGIRO && (
                <Bankgiro
                  onViewChange={setView}
                  linkId={linkId}
                  next={onBankgiroSubmit}
                  buttonName={<T>Confirm payout account</T>}
                  bankAccount={bank.bankAccount}
                />
              )}
              {confirmed !== Status.SUCCESS && view === View.KLARNA && (
                <Klarna
                  linkId={linkId}
                  next={onNext}
                  accounts={accounts}
                  onViewChange={setView}
                  onRetry={onRetry}
                  onChangeBank={onChangeBank}
                  status={status}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  country={country}
                  buttonName={<T>Confirm payout account</T>}
                />
              )}
            </div>
          </AnimateHeight>
        </div>

        <AnimateHeight name={confirmed}>
          <div>
            {confirmed === Status.SUCCESS ? (
              <SuccessBox relative>
                <b className="text-large">
                  <T>You're done!</T>
                </b>
                <p>
                  {viewerIsPrimary ? (
                    <T>
                      All is set. As soon as your application has been reviewed
                      you will receive a confirmation email.
                    </T>
                  ) : (
                    <T>All is set. Thank you for adding the bank account.</T>
                  )}
                </p>
                <p>
                  <T>It is safe to close this tab.</T>
                </p>
              </SuccessBox>
            ) : null}

            {confirmed === Status.ERROR ? (
              <ErrorBox relative>
                <p>
                  <T>Please select a payout account</T>
                </p>
              </ErrorBox>
            ) : null}
          </div>
        </AnimateHeight>
      </div>
    </StoryPage>
  );
};
