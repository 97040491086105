import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import cx from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Associate,
  AssociateOwner,
  AssociateRole,
  Id,
} from "../../../data/dataMerchant";
import { EditRow } from "../../../components/editRow/EditRow";
import { Badge } from "../../../components/badges/Badge";
import { T } from "../../../components/translation/T";
import { PersistentOverlay } from "../../../components/overlay/PersistantOverlay";
import { Steps } from "../../../components/steps/Steps";
import { AssociateId, Country } from "../../../data/models/ContractTypes";
import { CitizenshipAndSSN, getMatches } from "./steps/CitizenshipAndSSN";
import { NameAndOwnership } from "./steps/NameAndOwnership";
import { Done } from "./steps/Done";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";
import styles from "./OwnerOverlay.module.scss";
import { dataAssociates, isOwner } from "../../../data/dataAssociates";
import { useLinkId } from "../../../hooks/useLinkId";
import { useTranslation } from "react-i18next";
import { getIntlPercent } from "../../../components/utils";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../state/contractState";
import { routeState } from "../../../state/routeState";

interface Props {
  owner: Associate;
  associates: Associate[];
  owners: Associate[];
  hasMajorityOwners: boolean;
}

export interface IdValues extends Partial<Id>, Partial<AssociateOwner> {
  firstName?: string;
  lastName?: string;
  position?: string;
}

export const DATE_PATTERN = "yyyy-MM-dd";
export const MIN_LENGTH = 2;
export const MAX_LENGTH = 30;
export enum OwnershipType {
  OWNERSHIP = "ownership",
  VOTING_RIGHTS = "voting-rights",
}

export const HEADERS = ["Owner information", "Name and ownership", "Done"];

function getRemoveText(associate: Associate, t: any) {
  if (!associate.contact.firstName && !associate.contact.lastName) {
    return t("Do you want to remove this owner?");
  }

  if (associate.contact.firstName && associate.contact.lastName) {
    return t("Do you want to remove {{name}}?", {
      name: `${associate.contact.firstName} ${associate.contact.lastName}`,
    });
  }

  return t("Do you want to remove {{name}}?", {
    name: associate.contact.firstName || associate.contact.lastName,
  });
}

export function getAssociateFromValues(
  id: AssociateId,
  associate: Associate,
  values: IdValues,
  hasMajorityOwners: boolean
): Associate {
  const roles = associate.roles.includes(AssociateRole.BENEFICIAL_OWNER)
    ? associate.roles
    : [...associate.roles, AssociateRole.BENEFICIAL_OWNER];

  return {
    ...associate,
    associateId: id,
    contact: {
      ...associate.contact,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      position: values.position || "",
    },
    roles,
    id: {
      nationalPersonId: values.nationalPersonId,
      citizenships: values.citizenships || [],
      countryOfResidence: values.countryOfResidence || ("" as Country),
      countryOfBirth: values.countryOfBirth || ("" as Country),
    },
    owner: {
      designatedOwner: hasMajorityOwners,
      hasIndirectOwnership: values.hasIndirectOwnership || false,
      capitalStake: values.capitalStake || 0,
      votingRightsStake: values.votingRightsStake || 0,
    },
  };
}

const MIN_CAPITAL_STAKE = 25;
const MIN_VOTING_RIGHTS = 25;

export function isComplete(owner: Associate, hasMajorityOwners: boolean) {
  if (!owner.id) {
    return false;
  }

  if (!owner.id.nationalPersonId) {
    return false;
  }

  if (hasMajorityOwners) {
    return !!owner.contact.position;
  }

  if (!owner.owner) {
    return false;
  }

  if (undefined === owner.owner.hasIndirectOwnership) {
    return false;
  }

  if (!owner.owner.capitalStake && !owner.owner.votingRightsStake) {
    return false;
  }

  if (
    undefined !== owner.owner.capitalStake &&
    owner.owner.capitalStake >= MIN_CAPITAL_STAKE
  ) {
    return true;
  }

  if (
    undefined !== owner.owner.votingRightsStake &&
    owner.owner.votingRightsStake >= MIN_VOTING_RIGHTS
  ) {
    return true;
  }

  return false;
}

export const OwnerOverlay: React.FunctionComponent<Props> = ({
  owner: initialOwner,
  associates,
  owners,
  hasMajorityOwners,
}) => {
  const queryClient = useQueryClient();
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);
  const { access } = useRecoilValue(routeState);
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [owner, setOwner] = useState<Associate>(initialOwner);
  const indexRef = useRef<number>(0);
  const formRef = useRef<HTMLFormElement>(null);

  const removed = useCallback(() => {
    queryClient.invalidateQueries(["associates", linkId]);
  }, [linkId, queryClient]);

  const onRemove = useCallback(() => {
    if (owner.roles.length === 1 && isOwner(owner)) {
      return dataAssociates(access)
        .removeAssociate(linkId, owner)
        .then(removed);
    }

    return dataAssociates(access)
      .saveAssociate(
        linkId,
        {
          ...owner,
          roles: owner.roles.filter(
            (role) => role !== AssociateRole.BENEFICIAL_OWNER
          ),
        },
        false
      )
      .then(removed);
  }, [linkId, owner, access, removed]);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError: isSaveError,
    reset,
  } = useMutation(
    () => {
      const currentOwner = owners.find(
        (associate) => associate.associateId === owner.associateId
      );

      if (!currentOwner) {
        return Promise.reject();
      }

      // Yes, this is an new owner but perhaps the SSN
      // is matching a current signee?
      const match = getMatches(
        owner.id?.nationalPersonId || "-1",
        associates
      )[0];

      const roles = owner.roles.includes(AssociateRole.BENEFICIAL_OWNER)
        ? owner.roles
        : [...owner.roles, AssociateRole.BENEFICIAL_OWNER];

      const updatedOwner: Associate = {
        ...match,
        ...owner,
        roles,
        owner: {
          capitalStake: 0, // default value
          votingRightsStake: 0, // default value
          hasIndirectOwnership: false, // default value
          ...owner.owner,
          designatedOwner: hasMajorityOwners,
        } as AssociateOwner,
      };

      return dataAssociates(access).saveAssociate(linkId, updatedOwner);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["associates", linkId]);
        setIsOpen(false);
        setTimeout(() => {
          setIndex(0);
        }, 350);
      },
    }
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setIndex(0);
      reset();
    }, 350);
  }, [reset]);

  const onRetry = useCallback(() => {
    reset();
    setTimeout(onSave, 300);
  }, [reset, onSave]);

  useEffect(() => {
    if (index >= indexRef.current) {
      indexRef.current = index;
      return;
    }

    // We're going back in the overlay
    // reset errors
    reset();
    indexRef.current = index;
  }, [index, reset]);

  const fullName = useMemo(() => {
    if (!owner.contact.firstName || !owner.contact.lastName) {
      return <T>Missing name</T>;
    }

    return `${owner.contact.firstName} ${owner.contact.lastName}`;
  }, [owner.contact.firstName, owner.contact.lastName]);

  const associateIsOk = useMemo(
    () => isComplete(owner, hasMajorityOwners),
    [owner, hasMajorityOwners]
  );

  return (
    <div className={cx(styles.owner)}>
      <EditRow
        isValid={associateIsOk}
        setIsOpen={setIsOpen}
        onRemove={onRemove}
        confirmRemoveText={getRemoveText(owner, t)}
      >
        <div className={cx(styles.info)}>
          <div className={cx(styles.description)}>
            <b>{fullName}</b>
          </div>
          <AnimateHeightMotion presence>
            {!associateIsOk && (
              <Badge truncate className="text-small">
                <T>Please add information</T>
              </Badge>
            )}
            {associateIsOk && (
              <Badge truncate className="text-small">
                {hasMajorityOwners
                  ? owner.contact.position
                  : `${t(
                      owner.owner?.votingRightsStake
                        ? "Voting rights"
                        : "Capital stake"
                    )}: ${getIntlPercent(
                      i18n.language,
                      owner.owner?.capitalStake ||
                        owner.owner?.votingRightsStake ||
                        0,
                      0
                    )}`}
              </Badge>
            )}
          </AnimateHeightMotion>
        </div>
      </EditRow>

      <PersistentOverlay open={isOpen} onClose={close} className="full-height">
        <Steps
          index={index}
          header={HEADERS}
          length={HEADERS.length}
          insideOverlay
        >
          <CitizenshipAndSSN
            owner={owner}
            setOwner={setOwner}
            formRef={formRef}
            isViewer={contract.contractViewer.associateId === owner.associateId}
            setIndex={setIndex}
            associates={associates}
          />

          <NameAndOwnership
            owner={owner}
            setOwner={setOwner}
            setIndex={setIndex}
            hasMajorityOwners={hasMajorityOwners}
          />

          <Done
            setIndex={setIndex}
            index={index}
            onSave={onSave}
            onRetry={onRetry}
            isLoading={isSaving}
            isError={isSaveError}
          />
        </Steps>
      </PersistentOverlay>
    </div>
  );
};
